import { createSlice } from '@reduxjs/toolkit';
// utils
import suburbsApi from '../../api/suburbs';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'suburbs',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getSuburbsSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },

    suburbUpdated(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) state.list[index] = action.payload;
    },

    suburbSaved(state, action) {
      state.loading = false;
      state.list.push(action.payload);
    },

    suburbRemoved(state, action) {
      state.loading = false;
      state.list = state.list.filter((item) => item.id !== action.payload);
    },

    suburbsRollBack(state, action) {
      state.loading = false;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getSuburbsSuccess, suburbUpdated, suburbSaved, suburbRemoved, suburbsRollBack } =
  slice.actions;

// ----------------------------------------------------------------------

export function getSuburbs() {
  return async (dispatch, getState) => {
    const {
      suburbs: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await suburbsApi.getAll();
      dispatch(getSuburbsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updateSuburb(id, values) {
  const { state_id: stateId, ...data } = values;
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await suburbsApi.update(id, data);
      dispatch(
        suburbUpdated({
          id,
          state_id: stateId,
          ...data
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function saveSuburb(values) {
  const { state_id: stateId, ...data } = values;

  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await suburbsApi.save(data);
      dispatch(suburbSaved({ state_id: stateId, ...response.data }));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function removeSuburb(id) {
  return async (dispatch, getState) => {
    // optimistic approach
    const {
      suburbs: { list }
    } = { ...getState() };
    dispatch(suburbRemoved(id));

    try {
      dispatch(startLoading());
      const response = await suburbsApi.remove(id);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      dispatch(suburbsRollBack(list));
      throw error;
    }
  };
}
