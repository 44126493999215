import { createSlice } from '@reduxjs/toolkit';
// utils
import usersApi from '../../api/users';
import { ENTITY_STATUS } from '../../constants';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    gotUsersSucess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },
    userSaved(state, action) {
      state.loading = false;
      state.list = [{ ...action.payload }, ...state.list];
    },
    userEnabled(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload);
      if (index === -1) return;
      const users = [...state.list];
      users[index] = { ...users[index], status_id: ENTITY_STATUS.active };
      state.list = users;
    },
    userDisabled(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload);
      if (index === -1) return;
      const users = [...state.list];
      users[index] = { ...users[index], status_id: ENTITY_STATUS.disabled };
      state.list = users;
    },
    userUpdated(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload.userId);
      if (index === -1) return;
      const users = [...state.list];
      users[index] = { ...users[index], ...action.payload.data };
      state.list = users;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, gotUsersSucess, userSaved, userEnabled, userDisabled, userUpdated } =
  slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch, getState) => {
    const {
      users: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await usersApi.getAll();
      dispatch(gotUsersSucess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------
export function saveUser(data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await usersApi.saveUser(data);
      dispatch(userSaved(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function enableUser(userId) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await usersApi.enableUser(userId);
      dispatch(userEnabled(userId));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function disableUser(userId) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await usersApi.disableUser(userId);
      dispatch(userDisabled(userId));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updateUser(userId, data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await usersApi.updateUser(userId, data);
      dispatch(userUpdated({ userId, data }));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
