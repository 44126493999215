import { ENTITY_STATUS } from '../constants';
import axios from '../utils/axios';

const getAll = () => axios.get(`/users`);

const saveUser = (data) => axios.post(`/users`, data);

const enableUser = (userId) => axios.put(`/users/${userId}`, { status_id: ENTITY_STATUS.active });
const disableUser = (userId) => axios.put(`/users/${userId}`, { status_id: ENTITY_STATUS.disabled });

const updateUser = (userId, data) => axios.put(`/users/${userId}`, data);

const resetPasswordByAdmin = (userId, password) => axios.put(`/users/${userId}/reset-password`, { password });

export default {
  getAll,
  saveUser,
  enableUser,
  disableUser,
  updateUser,
  resetPasswordByAdmin
};
